.muuri-item-dragging {
  z-index: 100;
}

.muuri-item-releasing {
  z-index: 100;
}

/* not from Muuri lib, custom */
.muuri-item-resizing {
  z-index: 100;
}

.packing-grid-moving-box {
  z-index: 100;
  background-color: blueviolet;
  opacity: 25%;
}
