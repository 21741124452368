.popover {
  background: #e8cbc0; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to top,
    #636fa4,
    #e8cbc0
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    #636fa4,
    #e8cbc0
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
